<template>
  <div class="addArticle">
    <div class="title">
      <span>课程管理 / </span>
      <span class="black">课程{{ edit ? "编辑" : "新增" }} </span>
    </div>
    <el-card>
      <el-form :model="addForm" label-width="130px" :rules="rules" ref="addForm">
        <el-row :gutter="24">
          <el-col :xs="24" :md="14">
            <el-form-item label="所属板块：" prop="adaptorType">
              <el-select class="w_100" v-model="addForm.adaptorType" placeholder="请选择所属板块" @change="getThemetypeList">
                <el-option v-for="(item, index) in blackList" :key="index" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属分类：" prop="courseType" v-if="addForm.adaptorType">
              <el-select class="w_100" v-model="addForm.courseType" placeholder="请选择所属分类">
                <el-option v-for="(item, index) in typeList" :key="index" :label="item.typeName" :value="item.typeId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="课程名称：" prop="courseName">
              <el-input v-model="addForm.courseName" placeholder="请输入课程名称"></el-input>
            </el-form-item>
            <el-form-item label="来源：" prop="fromBy">
              <el-input v-model="addForm.fromBy" placeholder="请输入来源"></el-input>
            </el-form-item>
            <el-form-item label="简要描述：" prop="courseTitle">
              <el-input type="textarea" placeholder="选填" v-model="addForm.courseTitle" maxlength="120" :rows="4"
                show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="课时视频：" :required="true">
              <div class="topic" v-if="addForm.courseDetails&&addForm.courseDetails.length>0">
                <div v-for="(item, index) in addForm.courseDetails" :key="index">
                  <div class="flex">
                    <el-input style="width:280px;" v-model="item.name" placeholder="请选择课时视频">
                    </el-input>
                    <div class="zsgc">
                      <el-button class="span" type="text" @click="handleDelete(item,index)">删除</el-button>
                      <el-button class="span" type="text" @click="handleMoveUp(item,index)" :disabled="index === 0">上移
                      </el-button>
                      <el-button class="span" type="text" @click="handleMoveDown(item,index)"
                        :disabled="getFormLength(index)">下移</el-button>
                    </div>
                  </div>
                </div>
              </div>
              <el-button size="small" type="primary" @click="addCourses">添加课时</el-button>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :md="10">
            <el-form-item label="封面图片：" prop="coverUrl">
              <div class="flex">
                <el-upload :action="action1" accept=".png,.jpg,.jpeg,.bmp,.webp" :headers="headers"
                  :show-file-list="false" :on-success="handleAvatarSuccess">
                  <el-button size="small" type="primary">选择资源文件</el-button>
                </el-upload>
                <span class="upload_span_text">建议上传尺寸121pt*92pt，仅支持png/jpg/jpeg/bmp/webp格式</span>
              </div>
              <div>
                <img v-if="addForm.coverUrl" :src="addForm.coverUrl" class="img_240" />
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="课程介绍：" prop="courseIntroduce">
          <TinyMceEditor v-model="addForm.courseIntroduce" />
        </el-form-item>
        <el-form-item class="text_center">
          <!-- state 0上架  2待上架 -->
          <el-button type="primary" size="small" @click="submit('1')">上线</el-button>
          <el-button type="primary" size="small" plain @click="submit('0')">保存待投放</el-button>
          <el-button size="small" @click="cancel()">取消</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <!-- 云文件 -->
    <modal :obj="{
        title: '云文件',
        dialog: resetDialog,
        dialogText: 'resetDialog',
        form: fileInfo,
        ref: 'fileInfo',
        okText: '确认',
        height:'600px'
      }" @submit="dialogSubmit">
      <el-form :model="fileInfo" label-width="50px" ref="fileInfo">
        <el-form-item>
          <div class="flex">
            <el-input class="w_100" v-model="fileInfo.fileTitle" @input="$forceUpdate()" placeholder="请输入搜索文件内容"
              clearable prefix-icon="el-icon-search"></el-input>
            <el-button type="primary" style="height: 30px;margin-top: 4px;" size="small" @click="changeSearch(1)">查询
            </el-button>
          </div>
        </el-form-item>
        <div class="selectBox" v-if="vedioList&&vedioList.length>0">
          <div class="dis" v-for="(item, index) in vedioList" :key="index">
            <div :class="{active : active == item.id}" @click="clickItem(item)"> {{item.itemName}}</div>
          </div>
        </div>
      </el-form>
    </modal>
  </div>
</template>

<script>
  import TinyMceEditor from "@/components/TinyMceEditor";
  import modal from "@/components/modal";
  import {
    topicTypes,
    courseTimeList,
    files1,
    courseSave,
    courseUpdate,
    courseInfo,
    couldList
  } from "@/api/api";
  export default {
    components: {
      TinyMceEditor,
      modal
    },
    data() {
      return {
        size: 10, //每页条数
        current: 1, //当前条数
        total: 0,
        active: "",
        resetDialog: false,
        minioStyle: "",
        fileInfo: {
          id: "",
          fileTitle: "",
          type: 2

        },
        addForm: {
          courseName: "",
          fromBy: "",
          courseIntroduce: "",
          courseTitle: "",
          coverUrl: "",
          status: "",
          courseType: "",
          psyType: 6,
          courseDetails: [],
          psyCourseItemList: [],
          adaptorType: ""

        },
        blackList: [{
          id: 3,
          name: '学生课堂'
        }, {
          id: 4,
          name: '家长课堂'
        }],
        rules: {
          adaptorType: [{
            required: true,
            message: "请选择所属板块"
          }],
          courseType: [{
            required: true,
            message: "请选择所属分类"
          }],
          courseName: [{
            required: true,
            message: "请输入课程名称",
          }],
          // title: [{
          //   required: true,
          //   message: "请输入科普文章标题"
          // }],
          fromBy: [{
            required: true,
            message: "请输入来源"
          }],
          coverUrl: [{
            required: true,
            message: "请上传封面图片"
          }],

        },
        typeList: [],
        vedioList: [],
        action1: files1.url,
        loading: false, //上传状态
        externalLoading: false, //七牛和阿里上传loading
        percentage: 0, //上传进度
        timer: null, //上传进度定时器
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: this.$util.getToken("access_token"),
        },
        edit: "",
      };
    },
    computed: {
      previousForm() {
        return this.$store.state.previousForm;
      },
      uploadStyle() {
        return this.$store.state.uploadStyle;
      },
    },
    created() {
      if (this.$route.params.id != "true") {
        this.edit = true;
        this.getCourseInfo(this.$route.params.id);
      }
      if (this.$route.params.form) {
        this.$store.commit("setParam", {
          previousForm: this.$route.params.form
        });
      }
    },
    methods: {
      // 查看详情
      getCourseInfo(val) {
        this.$request(courseInfo, {
          courseId: val
        }).then((res) => {
          if (res.code === 0) {
            this.addForm = res.data;
            this.addForm.adaptorType = res.data.adaptorType
            this.getThemetypeList(this.addForm.adaptorType, 1)
            this.addForm.courseDetails = []
            if (res.data.psyCourseItemList && res.data.psyCourseItemList.length) {
              res.data.psyCourseItemList.forEach((item) => {
                this.addForm.courseDetails.push({
                  id: item.sourceId,
                  name: item.courseItemSource.itemName,
                  duration: item.courseItemSource.duration,
                  // itemSort: item.itemSort,
                  // url: item.contentUrl,
                })
              })
            }
          }
        });
      },
      getFormLength(index) {
        if (index === this.addForm.courseDetails.length - 1) return true;
        else return false;
      },
      handleDelete(item, index) {
        this.$forceUpdate()
        this.addForm.courseDetails.splice(index, 1);

        //删除
      },
      handleMoveUp(item, index) {
        //上移
        this.$forceUpdate()
        this.addForm.courseDetails.splice(index - 1, 0, item);
        this.addForm.courseDetails.splice(index + 1, 1);

      },
      handleMoveDown(item, index) {
        //下移
        this.$forceUpdate()
        this.addForm.courseDetails.splice(index + 2, 0, item);
        this.addForm.courseDetails.splice(index, 1);

      },
      changeSearch(val) {
        this.getFileList(val)
      },
      addCourses() {
        this.fileInfo.fileTitle = ""
        this.resetDialog = true
        this.getFileList()

      },
      clickItem(item) {
        this.active = item.id
        this.fileInfo.fileTitle = item.itemName
        this.fileInfo.id = item.id

      },
      dialogSubmit() {
        this.resetDialog = false
        this.vedioList.forEach((item) => {
          if (item.id == this.fileInfo.id) {
            this.addForm.courseDetails.push({
              id: item.id,
              name: item.itemName,
              duration: item.duration,
            })
          }
        })
        this.vedioList = []
      },

      // 上传封面图
      handleAvatarSuccess(res, file) {
        if (res.code == 0) {
          this.$util.message("suc", "上传成功");
          this.addForm.coverUrl = res.data.url;
          this.$refs.addForm.validate((result) => {
            return true
          })
        } else {
          this.$util.message("err", res.message);
        }
      },
      // 获取视频文件列表
      getFileList(val) {
        this.$request(couldList, {
          itemName: this.fileInfo.fileTitle,
        }).then((res) => {
          if (res.code === 0) {
            this.vedioList = res.data;
          }
        });
      },
      // 获取所属类型列表
      getThemetypeList(e, bool) {
        if (!bool) {
          this.addForm.courseType = ""
        }
        this.$request(topicTypes, {
          typeTheme: 6,
          adaptorType: e
        }).then((res) => {
          if (res.code === 0) {
            this.typeList = this.$util.superCopy(res.data);
          }
        });
      },
      submit(val) {
        let url = this.edit ? courseUpdate : courseSave;
        this.addForm.status = val
        this.addForm.psyCourseItemList = []
        if (this.addForm.courseDetails && this.addForm.courseDetails.length > 0) {
          this.addForm.courseDetails.forEach((item, index) => {
            this.addForm.psyCourseItemList.push({
              sourceId: item.id,
              courseItemSource: {
                duration: item.duration,
              }
            })
          })
          //  delete this.addForm.courseDetails
        } else {
          this.$util.message("err", "请添加课时视频");
          return
        }
        this.$refs.addForm.validate((result) => {
          if (result) {
            this.$request(url, this.addForm).then((res) => {
              if (res.code === 0) {
                this.$util.message("suc", "操作成功");
                if (this.edit) {
                  this.getCourseInfo(this.$route.params.id);
                }
                this.$router.push({
                  name: "onlineCourses",
                  params: {
                    form: this.previousForm
                  }
                });
              }
            });
          }
        });
      },
      cancel() {
        this.$router.push({
          name: "onlineCourses",
          params: {
            form: this.previousForm
          }
        });
      },
    },
  };
</script>

<style lang="less" scoped>
  .addArticle {
    .img_240 {
      width: 50%;
      min-width: 150px;
      max-width: 240px;
      height: 180px;
      border: 1px solid #eeeeee;
    }

    .topic {
      width: calc(100% - 40px);
      height: 200px;
      overflow-y: scroll;
      border: 1px solid #eee;
      padding: 20px;

      .zsgc {
        color: #409EFF;
        text-decoration: underline;

        .span {
          margin-left: 10px;
          cursor: pointer;
          text-decoration: underline;
        }
      }

    }

    .selectBox {
      height: 300px;
      line-height: 30px;
      text-align: center;
      overflow-y: scroll;
      border: 1px solid #eee;
      padding-bottom: 20px;
      margin: 0 50px;

      .dis {
        width: 100%;
        height: 40px;
      }

      .active {
        width: 99%;
        background: #eee;
        border: 1px solid #eee;
        // color: #fff;
      }
    }

    .w_104 {
      width: 104px;
    }

    /deep/.el-textarea__inner {
      padding: 5px 40px 5px 15px;
    }
  }
</style>